<script>
import i18n from '../i18n'
import { mapActions, mapGetters } from 'vuex'
import { layoutComputed } from '@/state/helpers'

/**
 * Horizontal-topbar component
 */
export default {
  props: {
    type: {
      type: String,
      required: true
    },
    width: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/currentUser'
    }),
    ...layoutComputed
  },
  components: {},
  data() {
    return {
      languages: [
        {
          flag: require('@/assets/images/flags/us.jpg'),
          language: 'en',
          title: 'English'
        },
        {
          flag: require('@/assets/images/flags/french.jpg'),
          language: 'fr',
          title: 'French'
        },
        {
          flag: require('@/assets/images/flags/spain.jpg'),
          language: 'es',
          title: 'spanish'
        },
        {
          flag: require('@/assets/images/flags/chaina.png'),
          language: 'zh',
          title: 'Chinese'
        },
        {
          flag: require('@/assets/images/flags/arabic.png'),
          language: 'ar',
          title: 'Arabic'
        }
      ],
      current_language: i18n.locale,
      text: null,
      flag: null,
      value: null
    }
  },
  mounted() {
    this.value = this.languages.find((x) => x.language === i18n.locale)
    this.text = this.value.title
    this.flag = this.value.flag
  },
  methods: {
    ...mapActions({
      signOut: 'user/signOut'
    }),
    logoutUser() {
      this.signOut().then(() => {
        this.$router.push('/login')
      })
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar()
    },
    toggleMenu() {
      let element = document.getElementById('topnav-menu-content')
      element.classList.toggle('show')
    },
    initFullScreen() {
      document.body.classList.toggle('fullscreen-enable')
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen()
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen()
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.current_language = locale
      this.text = country
      this.flag = flag
      i18n.locale = locale
    }
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case 'dark':
              document.body.setAttribute('data-topbar', 'dark')
              document.body.removeAttribute('data-layout-scrollable')
              break
            case 'light':
              document.body.setAttribute('data-topbar', 'light')
              document.body.removeAttribute('data-layout-size', 'boxed')
              document.body.removeAttribute('data-layout-scrollable')
              break
            case 'colored':
              document.body.setAttribute('data-topbar', 'colored')
              document.body.removeAttribute('data-layout-size', 'boxed')
              break
            default:
              document.body.setAttribute('data-topbar', 'dark')
              break
          }
        }
      }
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case 'boxed':
              document.body.setAttribute('data-layout-size', 'boxed')
              document.body.removeAttribute('data-layout-scrollable')
              break
            case 'fluid':
              document.body.setAttribute('data-layout-mode', 'fluid')
              document.body.removeAttribute('data-layout-scrollable')
              document.body.removeAttribute('data-layout-size')
              break
            case 'scrollable':
              document.body.setAttribute('data-layout-scrollable', 'true')
              document.body.removeAttribute('data-layout-mode')
              document.body.removeAttribute('data-layout-size')
              break
            default:
              document.body.setAttribute('data-layout-mode', 'fluid')
              break
          }
        }
      }
    }
  }
}
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/app/logos/logo_fluxsolar_blanco.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/app/logos/logo_fluxsolar_blanco.png" alt height="40" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/app/logos/logo_fluxsolar_blanco.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/app/logos/logo_fluxsolar_blanco.png" alt height="40" />
            </span>
          </router-link>
        </div>
        <button
          id="toggle"
          type="button"
          class="btn btn-sm me-2 font-size-16 d-lg-none header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>

      <div class="d-flex">
        <div class="dropdown d-none d-lg-inline-block ms-1">
          <button type="button" class="btn header-item noti-icon" @click="initFullScreen">
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>

        <b-dropdown right variant="black" toggle-class="header-item">
          <template v-slot:button-content>
            <span v-if="currentUser" class="ms-1">{{
              `${currentUser.first_name} ${currentUser.last_name}`
            }}</span>
            <i class="mdi mdi-chevron-down"></i>
          </template>
          <!-- item-->
          <b-dropdown-item>
            <router-link tag="span" to="/contacts/profile">
              <i class="bx bx-user font-size-16 align-middle me-1"></i>
              {{ $t('navbar.dropdown.henry.list.profile') }}
            </router-link>
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <a style="cursor: pointer" @click="logoutUser" class="dropdown-item text-danger">
            <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ $t('navbar.dropdown.henry.list.logout') }}
          </a>
        </b-dropdown>

        <!-- <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon right-bar-toggle toggle-right"
            @click="toggleRightSidebar"
          >
            <i class="bx bx-cog bx-spin toggle-right"></i>
          </button>
        </div> -->
      </div>
    </div>
  </header>
</template>
