<script>
import i18n from '../i18n'
import { mapActions, mapGetters } from 'vuex'
import { logoAppWhite } from '../constants/config'
/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      logoAppWhite,
      languages: [
        {
          flag: require('@/assets/images/flags/us.jpg'),
          language: 'en',
          title: 'English'
        },
        {
          flag: require('@/assets/images/flags/french.jpg'),
          language: 'fr',
          title: 'French'
        },
        {
          flag: require('@/assets/images/flags/spain.jpg'),
          language: 'es',
          title: 'Spanish'
        },
        {
          flag: require('@/assets/images/flags/chaina.png'),
          language: 'zh',
          title: 'Chinese'
        },
        {
          flag: require('@/assets/images/flags/arabic.png'),
          language: 'ar',
          title: 'Arabic'
        }
      ],
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null
    }
  },
  mounted() {
    this.value = this.languages.find((x) => x.language === i18n.locale)
    this.text = this.value.title
    this.flag = this.value.flag
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/currentUser'
    })
  },
  methods: {
    ...mapActions({
      signOut: 'user/signOut'
    }),
    toggleMenu() {
      this.$parent.toggleMenu()
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar()
    },
    initFullScreen() {
      document.body.classList.toggle('fullscreen-enable')
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen()
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen()
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.lan = locale
      this.text = country
      this.flag = flag
      i18n.locale = locale
    },
    logoutUser() {
      this.signOut().then(() => {
        this.$router.push('/login')
      })
    }
  }
}
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link tag="a" to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img :src="logoAppWhite" alt height="22" />
            </span>
            <span class="logo-lg">
              <img :src="logoAppWhite" alt height="17" />
            </span>
          </router-link>

          <router-link tag="a" to="/" class="logo logo-light">
            <span class="logo-sm">
              <img :src="logoAppWhite" alt height="17" />
            </span>
            <span class="logo-lg">
              <img :src="logoAppWhite" alt height="45" />
            </span>
          </router-link>
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>

      <div class="d-flex">
        <div class="dropdown d-none d-lg-inline-block ms-1">
          <button type="button" class="btn header-item noti-icon" @click="initFullScreen">
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>

        <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          menu-class="dropdown-menu-end"
        >
          <template v-slot:button-content>
            <span v-if="currentUser" class="ms-1">{{
              `${currentUser.first_name} ${currentUser.last_name}`
            }}</span>
            <i class="mdi mdi-chevron-down"></i>
          </template>
          <!-- item-->
          <b-dropdown-item>
            <router-link tag="span" to="/app/profile/me">
              <i class="bx bx-user font-size-16 align-middle me-1"></i>
              {{ $t('navbar.dropdown.henry.list.profile') }}
            </router-link>
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <a style="cursor: pointer" @click="logoutUser" class="dropdown-item text-danger">
            <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ $t('navbar.dropdown.henry.list.logout') }}
          </a>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>
