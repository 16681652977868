export const menuItems = [
  {
    id: 1,
    label: 'menuitems.menu.text',
    isTitle: true
  },
  {
    id: 2,
    label: 'menuitems.items.monitoring',
    icon: 'bxs-bar-chart-alt-2',
    link: '/app/monitoring'
  },
  {
    id: 3,
    label: 'menuitems.items.maintainers',
    icon: 'bx-file',
    admin: true,
    // badge: {
    //     variant: "info",
    //     text: "menuitems.dashboards.badge"
    // },
    subItems: [
      {
        id: 4,
        label: 'menuitems.items.projects',
        link: '/app/projects',
        parentId: 3
      },
      {
        id: 5,
        label: 'menuitems.items.tags',
        link: '/app/tags',
        parentId: 3
      },
      {
        id: 6,
        label: 'menuitems.items.teams',
        link: '/app/equipments',
        parentId: 3
      },
      {
        id: 7,
        label: 'menuitems.items.brands',
        link: '/app/trademarks',
        parentId: 3
      },
      {
        id: 8,
        label: 'menuitems.items.users',
        link: '/app/users',
        parentId: 3
      }
    ]
  },
  {
    id: 4,
    label: 'menuitems.items.notifications',
    icon: 'bx-bell',
    link: '/app/notifications',
    admin: true
  },
  {
    id: 5,
    label: 'menuitems.items.support_tickets',
    icon: 'bx-support',
    subItems: [
      {
        id: 9,
        label: 'menuitems.items.categories',
        link: '/app/tickets/categories',
        parentId: 5,
        superadmin: true
      },
      {
        id: 10,
        label: 'menuitems.items.list_general',
        link: '/app/tickets/list',
        parentId: 5
      }
    ]
  },
  {
    id: 6,
    label: 'menuitems.items.config',
    icon: 'bx-collection',
    link: '/app/configuration',
    superadmin: true
  }
]
