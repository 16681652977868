<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row justify-end">
        <div class="col-sm-12">
          {{ new Date().getFullYear() }} © {{ nameApp }}
          {{ countryApp === countryColombia ? 'SAS' : '' }}
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { nameApp, countryColombia, countryApp } from '@/constants/config'
export default {
  data() {
    return {
      nameApp,
      countryColombia,
      countryApp
    }
  }
}
</script>
